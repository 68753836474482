import { Box, FormControlLabel, InputAdornment, Stack, Switch, TextField } from "@mui/material";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import RegistryData from "./scaleComponents/registryComponent";
import { theme } from "@/theme";
import InfoBodyData from "./scaleComponents/bofyInfoComponent";
import SmokeData from "./scaleComponents/smokeComponent";
import PressureData from "./scaleComponents/pressureComponent";
import CholesterolData from "./scaleComponents/cholesterolComponent";
import { useTranslatedMessages } from "@/multiLanguage/i18n";
import CardiovascularEventsData from "./scaleComponents/cardiovascularEventComponent";
import { CustomButton } from "@/components";
import { ClientData } from "@/services/pharmacyService";
import { useEffect } from "react";
import PrivacyData from "./scaleComponents/privacyComponent";
import FooterFormData from "./scaleComponents/footerFormComponent";
import { constants } from "@/utils/constants";

const DefaultFormData: FormDataLopre = {
    //ANAGRAFICA
    name: "Test",
    surname: "Test",
    country: "italy",
    sex: "male",
    age: 41,
    //Dati fisici
    height: 189,
    weight: 83,
    //Dati fumatore
    actualSmoker: false,
    exSmokerSwitch: false,
    exSmokerYears: 0,
    //Pressione
    systolicPressure: 150,
    diastolicPressure: 150,
    //Cholesterol
    totalCholesterol: 116,
    hdlCholesterol: 27,
    //creatine
    creatinine: 10,
    //eventi cardiovasculari
    //cardiovascularEvent: false, // NON SERVE DA VEDERE COSA FARE LATO BK E SE SI PUO TOGLIERE
    cardiovascularEventSwitch: false,
    moreCardiovascularEvent: false,
    //diabet data
    //diabetes: false, // NON SERVE DA VEDERE COSA FARE LATO BK E SE SI PUO TOGLIERE
    diabetesSwitch: false,
    //privacy
    privacy_switch: false,
};
export type FormDataLopre = {
    //ANAGRAFICA
    name?: string;
    surname?: string;
    country: string;
    sex: string;
    age: number;
    //Dati fisici
    height?: number;
    weight?: number;
    //Dati fumatore
    actualSmoker: boolean;
    exSmokerSwitch: boolean;
    exSmokerYears?: number;
    //Pressione
    systolicPressure: number;
    diastolicPressure?: number;
    //Cholesterol
    totalCholesterol: number;
    hdlCholesterol: number;
    //creatine
    creatinine?: number;
    //eventi cardiovasculari
    //cardiovascularEvent: boolean, // NON SERVE DA VEDERE COSA FARE LATO BK E SE SI PUO TOGLIERE
    cardiovascularEventSwitch: boolean;
    moreCardiovascularEvent: boolean;
    //diabet
    //diabetes: boolean, // NON SERVE DA VEDERE COSA FARE LATO BK E SE SI PUO TOGLIERE
    diabetesSwitch?: boolean;
    privacy_switch?: boolean;
};

type FormProps = {
    onSubmit: SubmitHandler<FormDataLopre>;
    selectedClient?: ClientData;
};

export function LopreScaleHookForm({ onSubmit, selectedClient }: FormProps) {
    const formMethods = useForm<FormDataLopre>({
        mode: "onBlur",
        defaultValues: constants.IS_DEBUG ? { ...DefaultFormData } : undefined,
    });
    const lang = useTranslatedMessages();
    useEffect(() => {
        if (selectedClient) {
            formMethods.setValue("name", selectedClient.name);
            formMethods.setValue("surname", selectedClient.surname);
            formMethods.setValue("country", selectedClient.nationality);
        }
    }, [selectedClient]);

    return (
        <FormProvider {...formMethods}>
            <Box
                sx={{
                    borderRadius: "20px 20px 20px 20px",
                    backgroundColor: theme.palette.primary.light,
                }}
            >
                <form onSubmit={formMethods.handleSubmit(onSubmit)}>
                    <Stack gap={3} p={3}>
                        <RegistryData
                            disabledPatientData={selectedClient ? true : false}
                            defaultCountry={selectedClient?.nationality}
                            nameRequired={false}
                            surnameRequired={false}
                        />
                        <InfoBodyData heightRequired={false} weightRequired={false} />
                        <SmokeData />
                        <PressureData diastolicPressureRequired={false} />
                        <CholesterolData triglycerides={false} />
                        <Stack>
                            <TextField
                                {...formMethods.register("creatinine", {
                                    valueAsNumber: true,

                                    validate: (value) => {
                                        if (!value) {
                                            return true;
                                        }
                                        if (value < 0.1 || value > 10.3) {
                                            return lang.scales.scaleWithComponentShared.creatinine
                                                .errorText;
                                        }
                                        return true;
                                    },
                                })}
                                inputProps={{
                                    "data-testid": "form-component-creatinine",
                                    step: ".01",
                                    onWheel: (event: any) => {
                                        event.target.blur();
                                    },
                                }}
                                label={<>{lang.scales.scaleWithComponentShared.creatinine.label}</>}
                                type="number"
                                fullWidth
                                error={!!formMethods.formState.errors.creatinine}
                                helperText={formMethods.formState.errors.creatinine?.message || ""}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">mg/dL</InputAdornment>
                                    ),
                                }}
                            />
                        </Stack>

                        <Stack>
                            <FormControlLabel
                                control={<Switch {...formMethods.register("diabetesSwitch")} />}
                                label={lang.scales.scaleWithComponentShared.diabete2.label}
                                labelPlacement="start"
                                sx={{
                                    marginLeft: 0,
                                    marginTop: "10px",
                                    marginBottom: "10px",
                                    flexGrow: 1,
                                    "&.MuiFormControlLabel-labelPlacementStart": {
                                        justifyContent: "space-between",
                                    },
                                }}
                            />
                        </Stack>
                        <CardiovascularEventsData arteriopathy={false} />

                        <PrivacyData />
                        <FooterFormData />
                    </Stack>
                    <Stack flexDirection={"row"} justifyContent={"center"} mt={2}>
                        <Box>
                            <CustomButton
                                sx={{
                                    backgroundColor: theme.palette.secondary.dark,
                                    color: theme.palette.primary.light,
                                }}
                                label={lang.scales.shared.forms.calculate}
                                type="submit"
                            />
                        </Box>
                        {/* <Box >
                            <CustomButton
                                sx={{ backgroundColor: theme.palette.secondary.dark, color: theme.palette.primary.light }}

                                label="Errori"
                                onClick={() => printErrors(formMethods.formState.errors)}
                            />

                        </Box> */}
                    </Stack>
                </form>
            </Box>
        </FormProvider>
    );
}
