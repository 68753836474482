import { useEffect, useRef, useState } from "react";
import { Box, Container, useMediaQuery, useTheme } from "@mui/material";
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom";
import { motion } from "framer-motion";

import { CustomAppBar, BackButton } from "../components";
import { FirstResultDialog } from "../dialogs";
import { nameToScaleMap } from "@/scales";
import { useScaleStore } from "../states/formState";
import { useAuthService } from "@/services/authService";
import { useTranslatedMessages } from "@/multiLanguage/i18n";
import { ClientData } from "@/services/pharmacyService";
import { ErrorDialog } from "@/dialogs/ErrorDialog";
import { FormDataFree, FreeScaleHookForm } from "@/scales/reactHookFreeScale";
import {
    sumbitHookFormFree,
    sumbitHookFormGlycated,
    sumbitHookFormLopre,
} from "@/scales/scalesUtils/utils";
import { useLocalStorageService } from "@/states/localStorageState";
import { Network } from "@/services/network";
import { FormDataLopre, LopreScaleHookForm } from "@/scales/reactHookLopreScale";
import { FormDataGlycated, GlycatedScaleHookForm } from "@/scales/reactHookGlycatedScale";

export const sendData = async (body: any) => {
    const response = await Network.sendScaleData(body);
    return response;
};

export default function ScalePage() {
    const lang = useTranslatedMessages();

    const countries = lang.countries;
    const user = useLocalStorageService((state) => state.user);
    const hasInitialized = useRef(false);
    const { palette } = useTheme();
    const location = useLocation();
    const selectedClient: ClientData = location.state ? location.state?.client : null;
    //TODO: è necessario usare zustand per memorizzare questi dati? perché non usestate e basta?

    const navigate = useNavigate();

    const { isLoggedIn } = useAuthService();
    const setSelectedScale = useScaleStore((state) => state.setSelectedScale);

    const [error, setError] = useState<string>("");
    const [reportId, setReportId] = useState<string | null>(null);
    const [reportPrivateKey, setreportPrivateKey] = useState<string | null>(null);

    const breakpoints = useTheme().breakpoints;
    const isXs = useMediaQuery(breakpoints.only("xs"));
    const isLg = useMediaQuery(breakpoints.up("lg"));
    const [openDialogs, setOpenDialogs] = useState({ firstResult: false, errorDialog: false });

    const [scaleIndexes, setScaleIndexes] = useState<any>(null);
    useEffect(() => {
        if (!hasInitialized.current) {
            hasInitialized.current = true;
        }
    }, [hasInitialized.current]);

    function switchVisibilityDialog(name: string) {
        setOpenDialogs((prevOpenDialogs) => ({
            ...prevOpenDialogs,
            [name]: !prevOpenDialogs[name],
        }));
    }

    let { scaleName } = useParams();

    const isScaleAvailable = () => {
        switch (scaleName as keyof typeof nameToScaleMap) {
            case "lopreHolder":
                return isLoggedIn();
            case "scaleGlycated":
                return isLoggedIn();
            case "scaleFree":
                return true;
            default:
                return false;
        }
    };

    if (!isScaleAvailable()) {
        setSelectedScale(null);
        return <Navigate to={"/"} />;
    }

    const returnTypeScaleSubmit = (data: FormDataLopre | FormDataGlycated | FormDataFree) => {
        switch (scaleName as keyof typeof nameToScaleMap) {
            case "lopreHolder":
                const lopreData = data as FormDataLopre;
                return sumbitHookFormLopre(lopreData, countries, user, selectedClient, lang);
            case "scaleGlycated":
                const glycatedData = data as FormDataGlycated;
                return sumbitHookFormGlycated(glycatedData, countries, user, selectedClient, lang);
            case "scaleFree":
                const freeData = data as FormDataFree;
                return sumbitHookFormFree(freeData, countries, user, selectedClient, lang);
            default:
                return false;
        }
    };
    const returnFormComponet = () => {
        switch (scaleName as keyof typeof nameToScaleMap) {
            case "lopreHolder":
                return (
                    <div
                        style={{ paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px" }}
                        data-testid="lopre-form-component"
                    >
                        <LopreScaleHookForm
                            selectedClient={selectedClient}
                            onSubmit={sumbitHookForm}
                        ></LopreScaleHookForm>
                    </div>
                );
            case "scaleGlycated":
                return (
                    <div
                        style={{ paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px" }}
                        data-testid="glycated-form-component"
                    >
                        <GlycatedScaleHookForm
                            selectedClient={selectedClient}
                            onSubmit={sumbitHookForm}
                        ></GlycatedScaleHookForm>
                    </div>
                );
            case "scaleFree":
                return (
                    <div
                        style={{ paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px" }}
                        data-testid="form-component"
                    >
                        <FreeScaleHookForm
                            selectedClient={selectedClient}
                            onSubmit={sumbitHookForm}
                        ></FreeScaleHookForm>
                    </div>
                );
            default:
                return false;
        }
    };

    const sumbitHookForm = async (data: FormDataLopre | FormDataGlycated | FormDataFree) => {
        try {
            const body = returnTypeScaleSubmit(data);

            if (body && body.scale.scaleIndexes) {
                setScaleIndexes(body.scale.scaleIndexes);
            }

            const response = await sendData(body);

            setReportId(response.id);
            setreportPrivateKey(response.privateKey);

            switchVisibilityDialog("firstResult");
        } catch (e) {
            setError(e.message ? e.message : "");
            switchVisibilityDialog("errorDialog");
        }
    };

    return (
        <motion.div
            style={{
                backgroundColor: palette.secondary.main,
                height: isXs ? "auto" : "100%",
                minHeight: "100vh",
            }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0, transition: { duration: 0.2 } }}
        >
            <Container fixed disableGutters={isXs} style={{ height: "auto" }}>
                <Box
                    sx={{
                        ...(isXs && { height: "25%" }),
                        display: "flex",

                        flexDirection: "column",
                    }}
                >
                    <header>
                        <CustomAppBar
                            title={
                                scaleName === "scaleFree"
                                    ? lang.scales.scaleFree.scaleName
                                    : lang.scales.lopreHolder.scaleName
                            }
                            contentColor={palette.secondary.dark}
                            backButton={
                                <BackButton
                                    sx={{ marginRight: "10px", color: palette.secondary.dark }}
                                    onClick={() => {
                                        setSelectedScale(null);
                                        navigate(-1);
                                        window.scrollTo(0, 0);
                                    }}
                                />
                            }
                        />
                    </header>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: "1rem",
                            flexDirection: "column-reverse",
                            ...(isXs && { height: "11rem" }),
                        }}
                    >
                        <img
                            src={"/athletic.png"}
                            alt=""
                            style={{
                                ...(!isXs && { width: isLg ? "25%" : "35%" }),
                                ...(isXs && { height: "100%" }),
                                objectFit: "contain",
                            }}
                        />
                    </Box>
                </Box>
                {returnFormComponet()}
                {scaleIndexes ? (
                    <FirstResultDialog
                        isOpen={openDialogs.firstResult}
                        scaleName={scaleName ?? ""}
                        reportId={reportId}
                        scaleIndexes={scaleIndexes}
                        reportPrivateKey={reportPrivateKey}
                        closeDialog={() => switchVisibilityDialog("firstResult")}
                    />
                ) : null}
                <ErrorDialog
                    isOpen={openDialogs.errorDialog}
                    closeDialog={() => switchVisibilityDialog("errorDialog")}
                    test={error}
                ></ErrorDialog>
            </Container>
        </motion.div>
    );
}
