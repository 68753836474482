import { FormDataLopre } from "../reactHookLopreScale";
import { FormDataGlycated } from "../reactHookGlycatedScale";
import { FormDataFree } from "../reactHookFreeScale";
import {
    ScaleFreeIndex,
    getRiskLevelValue as getRiskLevelValueFree,
    getMartinHopkinsValue,
    riskFactors,
} from "./freeScaleModels&Utils";
import {
    LopreHolderIndex,
    getRiskLevelValue as getRiskLevelValueLopre,
} from "./lopreScaleModels&Utils";
import {
    ScaleGlycatedIndex,
    valueUomFieldGliecatedProperty,
    getGlycatedValue,
    getRiskLevelValue as getRiskLevelValueGlycated,
    getSpecificRecommendation,
} from "./glycatedScaleModels&Utils";

import {
    calculateBMI,
    friedewald,
    getCountryRiskValue,
    getEGFRValue,
    getEventProbabilityValue,
    getScore2Value,
    setSmoke,
} from "./shared";

import { martinHopkins, score2 } from "./constants";
import { Compilation } from "@/services/compilationService";

export function getRiskFreeScale(formData: FormDataFree, lang: any) {
    const countryRisk: string = getCountryRiskValue(formData.country);
    const smoke: boolean = setSmoke(formData.actualSmoker, formData.exSmokerYears!);
    let [a, b, c, d, e, f] = getScore2Value(formData, countryRisk, smoke);
    const score2value: number = score2[a][b][c][d][e][f];
    let [g, h] = getMartinHopkinsValue(formData);
    const martinHopkins_factor: number = martinHopkins[g][h];
    const ldlCholesterol: number =
        Math.round(
            friedewald(
                formData.totalCholesterol,
                formData.hdlCholesterol,
                formData.triglycerides,
                martinHopkins_factor
            ) 
        );
    const egfr =
        Math.round(getEGFRValue(formData.sex, formData.creatinine, formData.age) * 100) / 100;
    const bmi: number | null = calculateBMI(formData.weight, formData.height);
    const riskFactorNumber: number = riskFactors(
        formData,
        ldlCholesterol,
        smoke,
        typeof bmi === "number" ? bmi : 0
    );
    let [riskLevel, LDLTarget] = getRiskLevelValueFree(
        formData,
        score2value,
        ldlCholesterol,
        egfr,
        riskFactorNumber
    );
    if (riskLevel !== "moderateLow") {
        let LDLTarget1: number = LDLTarget;
        let LDLTarget2: number = ldlCholesterol / 2;
        LDLTarget = Math.min(LDLTarget1, LDLTarget2);
    }
    let cholesterolTarget: { target: number; cholesterolType: string } = {
        target: LDLTarget,
        cholesterolType: "LDL",
    };
    if (formData.triglycerides > 199) {
        cholesterolTarget = { target: LDLTarget + 30, cholesterolType: "non-HDL" };
    }
    const eventProbability: string = getEventProbabilityValue(formData.age, riskLevel, lang);

    return {
        score2: score2value,
        countryRisk: countryRisk,
        ldlCholesterol,
        egfr,
        riskLevel: riskLevel,
        eventProbability: eventProbability,
        cholesterolTarget: cholesterolTarget,
        smoke: smoke,
        bmi,
    };
}
export function getRiskLopreScale(formData: FormDataLopre, lang: any) {
    const countryRisk: string = getCountryRiskValue(formData.country);
    const smoke: boolean = setSmoke(formData.actualSmoker, formData.exSmokerYears!);
    let [a, b, c, d, e, f] = getScore2Value(formData, countryRisk, smoke);
    const score2value: number = score2[a][b][c][d][e][f];
    let egfr = null;
    let bmi = null;
    if (formData.creatinine) {
        egfr =
            Math.round(getEGFRValue(formData.sex, formData.creatinine, formData.age) * 100) / 100;
    }
    if (formData.weight && formData.height) {
        bmi = calculateBMI(formData.weight, formData.height);
    }

    let [riskLevel, LDLTarget] = getRiskLevelValueLopre(formData, score2value, egfr);

    const eventProbability: string = getEventProbabilityValue(formData.age, riskLevel, lang);

    return {
        score2: score2value,
        countryRisk: countryRisk,
        eventProbability: eventProbability,
        egfr,
        riskLevel: riskLevel,
        smoke: smoke,
        bmi,
    };
}

export function getRiskGlycatedScale(formData: FormDataGlycated, lang: any) {
    const countryRisk: string = getCountryRiskValue(formData.country);
    const smoke: boolean = setSmoke(formData.actualSmoker, formData.exSmokerYears!);
    let [a, b, c, d, e, f] = getScore2Value(formData, countryRisk, smoke);
    const score2value: number = score2[a][b][c][d][e][f];
    let egfr = null;
    let bmi = null;
    if (formData.creatinine) {
        egfr =
            Math.round(getEGFRValue(formData.sex, formData.creatinine, formData.age) * 100) / 100;
    }
    if (formData.weight && formData.height) {
        bmi = calculateBMI(formData.weight, formData.height);
    }

    let [riskLevel, LDLTarget] = getRiskLevelValueGlycated(
        formData,
        score2value,
        formData.glycatedUom,
        egfr
    );

    const eventProbability: string = getEventProbabilityValue(formData.age, riskLevel, lang);

    const glycatedField: valueUomFieldGliecatedProperty = {
        value: formData.glycatedValue!,
        uom: formData.glycatedUom!,
    };
    // creo un oggetto che contiene entrambe i valori con le due unità di misura
    let glycatedDoubleValue = getGlycatedValue(glycatedField);
    return {
        score2: score2value,
        countryRisk: countryRisk,
        eventProbability: eventProbability,
        egfr,
        riskLevel: riskLevel,
        smoke: smoke,
        bmi,
        glycatedDoubleValue: glycatedDoubleValue,
    };
}
export const sumbitHookFormFree = (
    formData: FormDataFree,
    countries: any,
    user: any,
    selectedClient: any,
    lang: any
) => {
    const { name, surname } = formData;

    delete formData.name;

    delete formData.surname;

    let scaleIndexes = getRiskFreeScale(formData, lang);

    const compilation = {
        scaleType: "scalefree",
        clientEmail: selectedClient ? selectedClient.email : null,
        clientName: selectedClient ? selectedClient.name : null,
        clientSurname: selectedClient ? selectedClient.surname : `${surname}`,
        userName: user ? user?.name : null,
        userId: user ? user?.id : null,
        pharmacyId: user ? user?.pharmacyId : null,
        submissionTime: new Date(),
    } as unknown as Compilation;
    const scale = {
        $type: "scalefree",
        ...formData,
        country: countries[formData.country] as string,
        ldlCholesterol: scaleIndexes.ldlCholesterol,
        noHdlCholesterol: formData.totalCholesterol - formData.hdlCholesterol,
        creatinine: parseFloat(((formData.creatinine * 10) / 10).toFixed(2)),

        scaleIndexes: scaleIndexes,
        //i SEGUENTI PARAMETRI SERVE PER PDF PER AVERE CONSISTENTI I DATI NEL TEMPO
        renalIsufficiency: scaleIndexes.egfr < 60 ? "Si" : "No",
        hypertension:
            formData.systolicPressure > 140 ||
            formData.diastolicPressure > 90 ||
            formData.hypertensionMedications
                ? "Si"
                : "No",
        dislipidemia: formData.totalCholesterol > 190 ? "Si" : "No",
        suggestion: "*" + lang.pdf.scales.scaleFree.helpers.wildchartInfo,
        recommendationCholesterolPharase:
            lang.pdf.scales.scaleFree.recommendations.cholesterol1 +
                " " +
                scaleIndexes.cholesterolTarget.cholesterolType +
                " " +
                lang.pdf.scales.scaleFree.recommendations.cholesterol2 +
                " " +
                Math.floor(scaleIndexes.cholesterolTarget.target) +
                " mg/dL." +
                scaleIndexes.cholesterolTarget.cholesterolType ===
            "LDL"
                ? lang.pdf.scales.scaleFree.recommendations.cholesterolLDL
                : lang.pdf.scales.scaleFree.recommendations.cholesterolNotHDL,
        recommendationBloodPressure: lang.pdf.scales.scaleFree.recommendations.bloodPressure,
        riskImageUsage: lang.pdf.scales.scaleFree.info.usage,
        riskImageMetrics: lang.pdf.scales.scaleFree.info.metrics,
    } as FormDataFree & { scaleIndexes: ScaleFreeIndex };
    const body = {
        compilation,
        scale,
    };
    /* formData.name = name;
    formData.surname = surname; */

    return body;
};

export const sumbitHookFormLopre = (
    formData: FormDataLopre,
    countries: any,
    user: any,
    selectedClient: any,
    lang: any
) => {
    const { name, surname } = formData;
    delete formData.name;
    delete formData.surname;
    let scaleIndexes = getRiskLopreScale(formData, lang);

    const compilation = {
        scaleType: "lopreholder",
        clientEmail: selectedClient ? selectedClient.email : null,
        clientName: selectedClient ? selectedClient.name : `${name} ${surname}`,
        clientSurname: selectedClient ? selectedClient.surname : `${surname}`,
        userName: user ? user?.name : null,
        userId: user ? user?.id : null,
        pharmacyId: user ? user?.pharmacyId : null,
        submissionTime: new Date(),
    } as unknown as Compilation;
    const scale = {
        $type: "lopreholder",
        ...formData,
        country: countries[formData.country],
        //check if patient put the creatinine
        creatinine: formData.creatinine
            ? parseFloat(((formData.creatinine * 10) / 10).toFixed(2))
            : undefined,
        egfr: scaleIndexes.egfr ? Math.floor(scaleIndexes.egfr) : undefined,
        suggestion: "*" + lang.pdf.scales.lopreHolder.helpers.wildchartInfo,
        recommendationBloodPressure: lang.pdf.scales.lopreHolder.recommendations.bloodPressure,
        scaleIndexes: scaleIndexes,
    } as FormDataLopre & { scaleIndexes: LopreHolderIndex };
    const body = {
        compilation,
        scale,
    };
    /* formData.name = name;
    formData.surname = surname; */

    return body;
};

export const sumbitHookFormGlycated = (
    formData: FormDataGlycated,
    countries: any,
    user: any,
    selectedClient: any,
    lang: any
) => {
    const { name, surname } = formData;
    delete formData.name;
    delete formData.surname;
    let scaleIndexes = getRiskGlycatedScale(formData, lang);

    const glycatedField = {
        value: formData.glycatedValue,
        uom: formData.glycatedUom,
    };
    const compilation = {
        scaleType: "scaleGlycated",
        clientEmail: selectedClient ? selectedClient.email : null,
        clientName: selectedClient ? selectedClient.name : null,
        clientSurname: selectedClient ? selectedClient.surname : `${surname}`,
        userName: user ? user?.name : null,
        userId: user ? user?.id : null,
        pharmacyId: user ? user?.pharmacyId : null,
        submissionTime: new Date(),
    } as unknown as Compilation;
    const scale = {
        $type: "scaleGlycated",
        ...formData,
        glycated: { ...glycatedField },
        country: countries[formData.country],
        //check if patient put the creatinine
        creatinine: formData.creatinine
            ? parseFloat(((formData.creatinine * 10) / 10).toFixed(2))
            : undefined,
        egfr: scaleIndexes.egfr ? Math.floor(scaleIndexes.egfr) : undefined,
        suggestion: "*" + lang.pdf.scales.scaleGlycated.helpers.wildchartInfo,
        recommendationBloodPressure: lang.pdf.scales.scaleGlycated.recommendations.bloodPressure,
        recommendationGlicated: getSpecificRecommendation(formData, scaleIndexes, lang),

        diabet2:
            formData.glycatedValue! > (formData.glycatedUom === "mmol/mol" ? 48 : 6.5)
                ? "Si"
                : "NO",
        scaleIndexes: scaleIndexes,
    } as unknown as FormDataGlycated & {
        scaleIndexes: ScaleGlycatedIndex;
        glycated: { value: number; uom: string };
    };
    const body = {
        compilation,
        scale,
    };
    /* formData.name = name;
    formData.surname = surname; */

    return body;
};
