import { useFormContext } from "react-hook-form";
import {
    TextField,
    Select,
    Typography,
    MenuItem,
    FormControl,
    InputLabel,
    FormControlLabel,
    Stack,
    Switch,
    IconButton,
    Popover,
} from "@mui/material";
import { useTranslatedMessages } from "@/multiLanguage/i18n";
import HelpIcon from "@mui/icons-material/Help";
import { useEffect, useState } from "react";
import { theme } from "@/theme";

function DiabetData() {
    const lang = useTranslatedMessages();

    const {
        register,
        formState: { errors },
        watch,
        setValue,
    } = useFormContext();
    const [infoTextIsOpen, setInfoTextIsOpen] = useState<boolean>(false);
    const [infoText, setInfoText] = useState<string>("");
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const isDiabetSelected = watch("diabetesSwitch");

    useEffect(() => {
        if (!isDiabetSelected) {
            setValue("diabetesSelect", "diabete1");
            setValue("diabetesAge", 0);
            setValue("diabetesDiseases", false);
        }
    }, [isDiabetSelected, setValue]);

    return (
        <Stack gap={3}>
            {/* <Typography variant='h4'>{lang.scales.diabetComponent.title} </Typography>
             */}

            <FormControlLabel
                control={
                    <Switch
                        {...register("diabetesSwitch")}
                        data-testid="form-component-diabetesSwitch"
                        onChange={(e) => setValue("diabetesSwitch", e.target.checked)}
                    />
                }
                label={lang.scales.diabetComponent.diabetes.label}
                labelPlacement="start"
                sx={{
                    marginLeft: 0,
                    marginTop: "10px",
                    marginBottom: "10px",
                    flexGrow: 1,
                    "&.MuiFormControlLabel-labelPlacementStart": {
                        justifyContent: "space-between",
                    },
                }}
            />

            {isDiabetSelected && (
                <>
                    <FormControl fullWidth>
                        <InputLabel>
                            {lang.scales.diabetComponent.diabetes.diabetesSelect.label}*
                        </InputLabel>
                        <Select
                            {...register("diabetesSelect")}
                            data-testid="form-component-diabetesSelect"
                            label={lang.scales.diabetComponent.diabetes.diabetesSelect.label}
                            defaultValue="diabete1"
                            inputProps={{
                                "data-testid": "form-component-diabetesSelect", // Aggiungi data-testid qui
                            }}
                            error={!!errors.diabetesSelect}
                        >
                            <MenuItem value="diabete1">
                                {lang.scales.diabetComponent.diabetes.diabetesSelect.diabete1}
                            </MenuItem>
                            <MenuItem value="diabete2">
                                {lang.scales.diabetComponent.diabetes.diabetesSelect.diabete2}
                            </MenuItem>
                        </Select>
                        {errors.diabetesSelect && <p>{String(errors.diabetesSelect.message)}</p>}
                    </FormControl>

                    <TextField
                        {...register("diabetesAge", {
                            valueAsNumber: true,
                            required: lang.scales.diabetComponent.diabetes.diabetesAge.errorText,
                            validate: (value) => {
                                if (!Number.isInteger(value) || value < 0 || value > 89) {
                                    return lang.scales.diabetComponent.diabetes.diabetesAge
                                        .errorText;
                                } else {
                                    return true;
                                }
                            },
                        })}
                        inputProps={{
                            "data-testid": "form-component-diabetesAge", // Aggiungi data-testid qui
                            step: ".01",
                            onWheel: (event: any) => {
                                event.target.blur();
                            },
                        }}
                        type="number"
                        label={lang.scales.diabetComponent.diabetes.diabetesAge.label}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        error={!!errors.diabetesAge}
                        helperText={errors.diabetesAge ? String(errors.diabetesAge?.message) : ""}
                    />

                    <FormControlLabel
                        control={
                            <Stack flexDirection={"row"} alignItems={"center"}>
                                <Switch
                                    {...register("diabetesDiseases")}
                                    data-testid="form-component-diabetesDiseases"
                                    onChange={(e) => setValue("diabetesDiseases", e.target.checked)}
                                />
                                <IconButton
                                    onClick={(event) => {
                                        setAnchorEl(event.currentTarget);
                                        setInfoText(
                                            lang.scales.diabetComponent.diabetes.diabetesDiseases
                                                .infoText
                                        );
                                        setInfoTextIsOpen(true);
                                    }}
                                    edge="end"
                                >
                                    <HelpIcon sx={{ color: theme.palette.secondary.light }} />
                                </IconButton>
                            </Stack>
                        }
                        label={lang.scales.diabetComponent.diabetes.diabetesDiseases.label}
                        labelPlacement="start"
                        sx={{
                            marginLeft: 0,
                            marginTop: "10px",
                            marginBottom: "10px",
                            flexGrow: 1,
                            "&.MuiFormControlLabel-labelPlacementStart": {
                                justifyContent: "space-between",
                            },
                        }}
                    />
                </>
            )}

            <Popover
                open={infoTextIsOpen}
                anchorEl={anchorEl}
                onClose={() => {
                    setInfoTextIsOpen(false);
                }}
                disableScrollLock
                transformOrigin={{
                    horizontal: "left",
                    vertical: "bottom",
                }}
            >
                <Typography sx={{ p: 2 }}>{infoText}</Typography>
            </Popover>
        </Stack>
    );
}

export default DiabetData;
