import { useFormContext } from "react-hook-form";
import {
    TextField,
    Select,
    Typography,
    MenuItem,
    FormControl,
    InputLabel,
    Box,
    Stack,
    InputAdornment,
    IconButton,
    Popover,
    FormHelperText,
} from "@mui/material";
import { useTranslatedMessages } from "@/multiLanguage/i18n";

import { getCountryOptions } from "@/utils/utils";
import HelpIcon from "@mui/icons-material/Help";
import { theme } from "@/theme";
import { useState } from "react";
import { countryRiskMap } from "../scalesUtils/constants";

interface InputRegistryDataProps {
    disabledPatientData?: boolean;
    defaultCountry?: string;
    nameRequired?: boolean;
    surnameRequired?: boolean;
    countryRequired?: boolean;
    sexRequired?: boolean;
    ageRequired?: boolean;
}

function RegistryData({
    disabledPatientData = false,
    defaultCountry = "italy",
    nameRequired = true,
    surnameRequired = true,
    countryRequired = true,
    sexRequired = true,
    ageRequired = true,
}: InputRegistryDataProps) {
    const { register, formState } = useFormContext();
    const lang = useTranslatedMessages();
    const [infoTextIsOpen, setInfoTextIsOpen] = useState<boolean>(false);
    const [infoText, setInfoText] = useState<string>("");
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    return (
        <Stack gap={3}>
            <Box>
                <TextField
                    {...register("name", {
                        required: nameRequired
                            ? lang.scales.registryComponent.name.textError
                            : undefined,
                    })}
                    disabled={disabledPatientData}
                    inputProps={{
                        "data-testid": "form-component-name", // Aggiungi data-testid qui
                    }}
                    label={
                        <>
                            {lang.scales.registryComponent.name.label}
                            {nameRequired ? "*" : null}
                        </>
                    }
                    fullWidth
                    error={!!formState.errors.name}
                    helperText={
                        formState.errors.name ? String(formState.errors.name.message) : undefined
                    }
                />
            </Box>
            <Box>
                <TextField
                    {...register("surname", {
                        required: surnameRequired
                            ? lang.scales.registryComponent.surname.textError
                            : undefined,
                    })}
                    disabled={disabledPatientData}
                    inputProps={{
                        "data-testid": "form-component-surname",
                    }}
                    label={
                        <>
                            {lang.scales.registryComponent.surname.label}
                            {surnameRequired ? "*" : null}
                        </>
                    }
                    fullWidth
                    error={!!formState.errors.surname}
                    helperText={
                        formState.errors.surname
                            ? String(formState.errors.surname?.message)
                            : undefined
                    }
                />
            </Box>

            <Box>
                <FormControl fullWidth>
                    <InputLabel>
                        {lang.scales.registryComponent.country.label}
                        {countryRequired ? "*" : null}
                    </InputLabel>
                    <Select
                        {...register("country", {
                            required: countryRequired
                                ? lang.scales.registryComponent.country.errorText
                                : undefined,
                        })}
                        defaultValue={defaultCountry}
                        disabled={disabledPatientData}
                        inputProps={{
                            "data-testid": "form-component-country",
                        }}
                        label={lang.scales.registryComponent.country.label}
                        error={!!formState.errors.country}
                    >
                        {getCountryOptions(
                            lang.countries as { [key: string]: string },
                            countryRiskMap
                        ).map((country) => {
                            return (
                                <MenuItem key={country.value} value={country.value}>
                                    {country.label}
                                </MenuItem>
                            );
                        })}
                    </Select>
                    {formState.errors.country && (
                        <FormHelperText error>
                            {String(formState.errors.country.message)}
                        </FormHelperText>
                    )}
                </FormControl>
            </Box>
            <Box>
                <FormControl fullWidth>
                    <InputLabel>
                        {lang.scales.registryComponent.sex.label}
                        {sexRequired ? "*" : null}
                    </InputLabel>
                    <Select
                        {...register("sex", {
                            required: sexRequired
                                ? lang.scales.registryComponent.sex.errorText
                                : undefined,
                        })}
                        /* value={getValues().sex ? getValues().sex : ""} */
                        inputProps={{
                            "data-testid": "form-component-sex", // Aggiungi data-testid qui
                        }}
                        label={lang.scales.registryComponent.sex.label}
                        error={!!formState.errors.sex}
                    >
                        <MenuItem key={"male"} value="male">
                            {lang.scales.registryComponent.sex.male}
                        </MenuItem>
                        <MenuItem key={"female"} value="female">
                            {lang.scales.registryComponent.sex.female}
                        </MenuItem>
                    </Select>

                    {formState.errors.sex && (
                        <FormHelperText error>
                            {String(formState.errors.sex.message)}
                        </FormHelperText>
                    )}
                </FormControl>
            </Box>
            <Box>
                <TextField
                    {...register("age", {
                        valueAsNumber: true,
                        required: ageRequired
                            ? lang.scales.registryComponent.age.errorText
                            : undefined,
                        validate: (value) => {
                            if (!Number.isInteger(value) || value < 40 || value > 89) {
                                return lang.scales.registryComponent.age.errorText;
                            } else {
                                return true;
                            }
                        },
                    })}
                    inputProps={{
                        "data-testid": "form-component-age", // Aggiungi data-testid qu
                        step: ".01",
                        onWheel: (event: any) => {
                            event.target.blur();
                        },
                    }}
                    label={
                        <>
                            {lang.scales.registryComponent.age.label}
                            {ageRequired ? "*" : null}
                        </>
                    }
                    type="number"
                    fullWidth
                    error={!!formState.errors.age}
                    helperText={
                        formState.errors.age
                            ? String(formState.errors.age?.message)
                            : lang.scales.registryComponent.age.hintText
                    }
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                        setAnchorEl(event.currentTarget);
                                        setInfoText(lang.scales.registryComponent.age.infoText);
                                        setInfoTextIsOpen(true);
                                    }}
                                    edge="end"
                                >
                                    {<HelpIcon sx={{ color: theme.palette.secondary.light }} />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>
            <Popover
                open={infoTextIsOpen}
                anchorEl={anchorEl}
                onClose={() => {
                    setInfoTextIsOpen(false);
                }}
                disableScrollLock
                transformOrigin={{
                    horizontal: "left",
                    vertical: "bottom",
                }}
            >
                <Typography sx={{ p: 2 }}>{infoText}</Typography>
            </Popover>
        </Stack>
    );
}

export default RegistryData;
