import { FormDataFree } from "../reactHookFreeScale";
import { RiskLevels, returnValue } from "./shared";

export type ScaleFreeIndex = {
    score2: number;
    countryRisk: string;
    ldlCholesterol: number;
    egfr: number;
    riskLevel: string;
    eventProbability: string;
    cholesterolTarget: { target: number; cholesterolType: string };
    smoke: boolean;
    bmi: number | null;
};

/**
 * Calcola il livello di rischio basato su dati specifici e parametri.
 *
 * @param {FormDataFree} formData - I dati del modulo.
 * @param {number} score2value - Il punteggio Score2 calcolato.
 * @param {number} ldlCholesterol - Il livello di lipoproteine a bassa densità (LDL).
 * @param {number} egfr - Il tasso di filtrazione glomerulare stimato (eGFR).
 * @param {number} riskFactors - Il fattore di rischio.
 * @returns {[keyof typeof RiskLevels, number]} Un array contenente il livello di rischio e un valore numerico aggiuntivo.
 * @throws {Error} Se si verifica un errore non gestito.
 */
export function getRiskLevelValue(
    formData: FormDataFree,
    score2value: number,
    ldlCholesterol: number,
    egfr: number,
    riskFactors: number
): [keyof typeof RiskLevels, number] {
    //TODO creare struttura esterna per tutto sto ambaradam?
    //Ha avuto più di due eventi cardiovascolari in due anni consecutivi
    if (formData.cardiovascularEventSwitch && formData.moreCardiovascularEvent) {
        return returnValue("extreme");
    }
    if (
        //score2
        (score2value >= 7.5 && formData.age < 50) ||
        (score2value >= 10 && formData.age >= 50 && formData.age < 70) ||
        (score2value >= 15 && formData.age >= 70) ||
        // Ha avuto almeno un evento cardiovascolare
        formData.cardiovascularEventSwitch ||
        //malattia cardiovascolare su base aterosclerotica
        formData.arteriopathy ||
        //FH con evento oppure un altro fattore di rischio
        (formData.familyHychol && riskFactors >= 1) ||
        //MRC severa:
        egfr < 30 ||
        (egfr > 29 && egfr < 45 && formData.acr && formData.acr > 29) ||
        //DM1 o DM2 con o danno d'organo o più di 2 fattori di rischio
        (formData.diabetesSwitch && riskFactors >= 1) ||
        egfr < 45 ||
        (egfr > 44 && egfr < 60 && formData.acr && formData.acr > 29 && formData.acr < 301) ||
        (formData.acr && formData.acr > 300) ||
        formData.diabetesDiseases ||
        //Ha DM1 da almeno 20 anni
        (formData.diabetesSwitch &&
            formData.diabetesSelect === "diabete1" &&
            formData.diabetesAge &&
            formData.age - formData.diabetesAge >= 20)
    ) {
        return returnValue("veryHigh");
    }
    if (
        //score2
        (score2value >= 2.5 && score2value < 7.5 && formData.age < 50) ||
        (score2value >= 5 && score2value < 10 && formData.age >= 50 && formData.age < 70) ||
        (score2value >= 7.5 && score2value < 15 && formData.age >= 70) ||
        //col elevati o pressione alta
        formData.totalCholesterol > 310 ||
        ldlCholesterol > 190 ||
        (formData.systolicPressure > 179 && formData.diastolicPressure > 109) ||
        //FH senza fattori di rischio
        (riskFactors < 1 && formData.familyHychol) ||
        //MRC moderata
        (egfr > 29 && egfr < 45 && formData.acr && formData.acr < 30) ||
        (egfr > 44 && egfr < 60 && formData.acr && formData.acr > 29 && formData.acr < 301) ||
        (egfr > 59 && formData.acr && formData.acr > 300) ||
        //Ha DM2 senza danni d'organo da almeno 10 anni ed un altro fattore di rischio
        (formData.diabetesSwitch &&
            formData.diabetesSelect === "diabete2" &&
            formData.diabetesAge &&
            formData.age - formData.diabetesAge >= 10 &&
            riskFactors > 0)
    ) {
        return returnValue("high");
    }
    if (
        (score2value < 2.5 && formData.age < 50) ||
        (score2value < 5 && formData.age >= 50 && formData.age < 70) ||
        (score2value < 7.5 && formData.age >= 70) ||
        (formData.diabetesSelect === "diabete1" &&
            formData.diabetesAge &&
            formData.diabetesAge > 40) ||
        (formData.diabetesSelect === "diabete2" &&
            formData.diabetesAge &&
            formData.diabetesAge - formData.age < 10 &&
            riskFactors === 0)
    ) {
        return returnValue("moderateLow");
    }
    //TODO: come gestire l'errore?
    return ["moderateLow", -1];
}

/**
 * Calcola il punteggio Martin-Hopkins basato su determinati parametri di input.
 *
 * @param {FormDataFree} formData - I dati del modulo.
 * @returns {number[]} Un array di valori numerici che rappresentano il punteggio calcolato.
 *   - [0] - Punteggio trigliceridi (a): 0-28 a seconda dei trigliceridi.
 *   - [1] - Punteggio colesterolo non HDL (b): 0-5 a seconda del colesterolo non HDL.
 * @throws {Error} Se i parametri di input sono invalidi.
 */
export function getMartinHopkinsValue(formData: FormDataFree): number[] {
    const { triglycerides, hdlCholesterol, totalCholesterol } = formData;
    const noHdlCholesterol: number = totalCholesterol - hdlCholesterol;
    let a, b;
    if (triglycerides >= 7 && triglycerides <= 49) {
        a = 0;
    } else if (triglycerides > 49 && triglycerides <= 56) {
        a = 1;
    } else if (triglycerides > 56 && triglycerides <= 61) {
        a = 2;
    } else if (triglycerides > 61 && triglycerides <= 66) {
        a = 3;
    } else if (triglycerides > 66 && triglycerides <= 71) {
        a = 4;
    } else if (triglycerides > 71 && triglycerides <= 75) {
        a = 5;
    } else if (triglycerides > 75 && triglycerides <= 79) {
        a = 6;
    } else if (triglycerides > 79 && triglycerides <= 83) {
        a = 7;
    } else if (triglycerides > 83 && triglycerides <= 87) {
        a = 8;
    } else if (triglycerides > 87 && triglycerides <= 92) {
        a = 9;
    } else if (triglycerides > 92 && triglycerides <= 96) {
        a = 10;
    } else if (triglycerides > 96 && triglycerides <= 100) {
        a = 11;
    } else if (triglycerides > 100 && triglycerides <= 105) {
        a = 12;
    } else if (triglycerides > 105 && triglycerides <= 110) {
        a = 13;
    } else if (triglycerides > 110 && triglycerides <= 115) {
        a = 14;
    } else if (triglycerides > 115 && triglycerides <= 120) {
        a = 15;
    } else if (triglycerides > 120 && triglycerides <= 126) {
        a = 16;
    } else if (triglycerides > 126 && triglycerides <= 132) {
        a = 17;
    } else if (triglycerides > 132 && triglycerides <= 138) {
        a = 18;
    } else if (triglycerides > 138 && triglycerides <= 146) {
        a = 19;
    } else if (triglycerides > 146 && triglycerides <= 154) {
        a = 20;
    } else if (triglycerides > 154 && triglycerides <= 163) {
        a = 21;
    } else if (triglycerides > 163 && triglycerides <= 173) {
        a = 22;
    } else if (triglycerides > 173 && triglycerides <= 185) {
        a = 23;
    } else if (triglycerides > 185 && triglycerides <= 201) {
        a = 24;
    } else if (triglycerides > 201 && triglycerides <= 220) {
        a = 25;
    } else if (triglycerides > 220 && triglycerides <= 247) {
        a = 26;
    } else if (triglycerides > 247 && triglycerides <= 292) {
        a = 27;
    } else if (triglycerides > 292 && triglycerides <= 399) {
        a = 27;
    } else if (triglycerides > 399 && triglycerides <= 13975) {
        a = 28;
    } else {
        throw new Error("invalid triglycerides");
    }

    if (noHdlCholesterol <= 100) {
        b = 0;
    } else if (noHdlCholesterol > 100 && noHdlCholesterol <= 129) {
        b = 1;
    } else if (noHdlCholesterol > 129 && noHdlCholesterol <= 159) {
        b = 2;
    } else if (noHdlCholesterol > 159 && noHdlCholesterol <= 189) {
        b = 3;
    } else if (noHdlCholesterol > 189 && noHdlCholesterol <= 219) {
        b = 4;
    } else {
        b = 5;
    }

    return [a, b];
}

/**
 * Calcola il numero del fattore di rischio basati su dati specifici e parametri.
 *
 * @param {any} formData - I dati del modulo.
 * @param {number} LDL - Il livello di lipoproteine a bassa densità (LDL).
 * @param {boolean} smoke - Indica se il paziente è un fumatore o no.
 * @param {number} bmi - L'Indice di Massa Corporea (BMI) del paziente.
 * @returns {number} Il numero di fattori di rischio calcolato.
 */
export function riskFactors(formData: any, LDL: number, smoke: boolean, bmi: number): number {
    let riskCount: number = 0;
    riskCount += formData.totalCholesterol > 310 ? 1 : 0;
    riskCount += LDL > 190 ? 1 : 0;
    riskCount += formData.systolicPressure > 180 ? 1 : 0;
    riskCount += formData.diastolicPressure > 110 ? 1 : 0;
    riskCount += formData.hypertensionMedications ? 1 : 0;
    riskCount += smoke ? 1 : 0;
    riskCount += bmi > 30 ? 1 : 0;
    return riskCount;
}
