import { useFormContext } from "react-hook-form";
import {
    TextField,
    Typography,
    Box,
    Stack,
    InputAdornment,
    IconButton,
    Popover,
} from "@mui/material";
import { useTranslatedMessages } from "@/multiLanguage/i18n";
import HelpIcon from "@mui/icons-material/Help";
import { theme } from "@/theme";
import { useEffect, useState } from "react";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";

function GlycatedData() {
    const {
        setValue,
        watch,
        register,
        trigger,
        formState: { errors },
    } = useFormContext();
    const lang = useTranslatedMessages();
    const [infoTextIsOpen, setInfoTextIsOpen] = useState<boolean>(false);
    const [infoText, setInfoText] = useState<string>("");
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const glycatedEndTextSelected = watch("glycatedUom");

    useEffect(() => {
        if (!glycatedEndTextSelected) {
            setValue("glycatedUom", "mmol/mol");
        }
    }, [glycatedEndTextSelected, setValue]);

    const handleClick = (endText: string[]) => {
        const index = endText.indexOf(glycatedEndTextSelected);
        if (index === -1 || index + 1 === endText.length) {
            setValue("glycatedUom", endText[0]);
        } else {
            setValue("glycatedUom", endText[index + 1]);
        }
        trigger("glycatedValue");
    };
    return (
        <Stack gap={1}>
            {/* <Typography variant='h4'>{lang.scales.pressureComponent.title} </Typography>
             */}
            <Box>
                <TextField
                    {...register("glycatedValue", {
                        valueAsNumber: true,
                        required: lang.scales.glycatedComponent.glycated.errorText,
                        validate: (value) => {
                            if (
                                glycatedEndTextSelected === "mmol/mol" &&
                                (value < 40.9 || value > 132.1)
                            ) {
                                return lang.scales.glycatedComponent.glycated.errorText;
                            } else if (
                                glycatedEndTextSelected === "%" &&
                                (value < 5.89 || value > 14.21)
                            ) {
                                return lang.scales.glycatedComponent.glycated.errorText;
                            } else {
                                return true;
                            }
                        },
                    })}
                    label={<>{lang.scales.glycatedComponent.glycated.label}*</>}
                    type="number"
                    inputProps={{
                        step: ".01",
                        onWheel: (event: any) => {
                            event.target.blur();
                        },
                    }}
                    fullWidth
                    error={!!errors.glycatedValue}
                    helperText={errors.glycatedValue ? String(errors.glycatedValue?.message) : ""}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <Stack
                                    justifyContent={"end"}
                                    flexDirection={"row"}
                                    sx={{
                                        textAlign: "center",
                                        width: ["8rem"],
                                        color: theme.palette.secondary.dark,
                                        cursor: "pointer",
                                        padding: "5px",
                                    }}
                                >
                                    {glycatedEndTextSelected}
                                    <ChangeCircleIcon
                                        onClick={() => handleClick(["mmol/mol", "%"])}
                                    ></ChangeCircleIcon>
                                </Stack>
                                <IconButton
                                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                        setAnchorEl(event.currentTarget);
                                        setInfoText(
                                            lang.scales.glycatedComponent.glycated.infoText
                                        );
                                        setInfoTextIsOpen(true);
                                    }}
                                    edge="end"
                                >
                                    {<HelpIcon sx={{ color: theme.palette.secondary.light }} />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>

            <Popover
                open={infoTextIsOpen}
                anchorEl={anchorEl}
                onClose={() => {
                    setInfoTextIsOpen(false);
                }}
                disableScrollLock
                transformOrigin={{
                    horizontal: "left",
                    vertical: "bottom",
                }}
            >
                <Typography sx={{ p: 2 }}>{infoText}</Typography>
            </Popover>
        </Stack>
    );
}

export default GlycatedData;
