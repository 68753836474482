import { useFormContext, Controller } from "react-hook-form";
import {
    Select,
    Typography,
    MenuItem,
    FormControl,
    InputLabel,
    FormControlLabel,
    Stack,
    Switch,
    Popover,
    Box,
} from "@mui/material";
import { useTranslatedMessages } from "@/multiLanguage/i18n";
import { useEffect, useState } from "react";

import {
    AlbConstant,
    AscConstant,
    BilConstant,
    BloConstant,
    CaConstant,
    CreConstant,
    GluConstant,
    KetConstant,
    LeuConstant,
    NitConstant,
    PhConstant,
    ProConstant,
    SgConstant,
    UroConstant,
} from "../scalesUtils/constants";
import {
    showMarkInterval,
    determineAcrCategory,
    findValueByMark,
} from "../scalesUtils/glycatedScaleModels&Utils";

interface InputRegistryDataProps {
    ascRequired?: boolean;
    leuRequired?: boolean;
    gluRequired?: boolean;
    albRequired?: boolean;
    bilRequired?: boolean;
    phRequired?: boolean;
    sgRequired?: boolean;
    ketRequired?: boolean;
    proRequired?: boolean;
    nitRequired?: boolean;
    uroRequired?: boolean;
    creRequired?: boolean;
    bloRequired?: boolean;
    caRequired?: boolean;
}

function UrineAnalyzerData({
    ascRequired = true,
    leuRequired = true,
    gluRequired = true,
    albRequired = true,
    bilRequired = true,
    phRequired = true,
    sgRequired = true,
    ketRequired = true,
    proRequired = true,
    nitRequired = true,
    uroRequired = true,
    creRequired = true,
    bloRequired = true,
    caRequired = true,
}: InputRegistryDataProps) {
    const lang = useTranslatedMessages();

    const { control, watch, setValue } = useFormContext();
    const [infoTextIsOpen, setInfoTextIsOpen] = useState<boolean>(false);
    const [infoText, setInfoText] = useState<string>("");
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const alb = watch("alb");
    const cre = watch("cre");
    const isUrineAnalyzerSwitch = watch("urineAnalyzerSwitch");

    useEffect(() => {
        if (findValueByMark(alb, AlbConstant) && findValueByMark(cre, CreConstant)) {
            const acr = determineAcrCategory(
                findValueByMark(alb, AlbConstant)!.concentrationValue,
                findValueByMark(cre, CreConstant)!.concentrationValue
            );
            setValue("newAcr", acr);
        }
    }, [alb, cre, setValue]);

    return (
        <Stack gap={0}>
            {/* <Typography variant='h4'>{lang.scales.urineAnalyzerComponent.title} </Typography> */}

            <Controller
                name="urineAnalyzerSwitch"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                    <FormControlLabel
                        control={
                            <Switch {...field} data-testid="form-component-urineAnalyzerSwitch" />
                        }
                        label={lang.scales.urineAnalyzerComponent.urineAnalyzerSwitch.label}
                        labelPlacement="start"
                        sx={{
                            marginLeft: 0,
                            marginTop: "10px",
                            marginBottom: "10px",
                            flexGrow: 1,
                            "&.MuiFormControlLabel-labelPlacementStart": {
                                justifyContent: "space-between",
                            },
                        }}
                    />
                )}
            />

            {isUrineAnalyzerSwitch && (
                <Stack gap={3}>
                    <Controller
                        name="asc"
                        control={control}
                        rules={{
                            required: ascRequired
                                ? lang.scales.urineAnalyzerComponent.errorText
                                : undefined,
                        }}
                        render={({ field, fieldState }) => (
                            <FormControl fullWidth>
                                <InputLabel>
                                    {lang.scales.urineAnalyzerComponent.asc.label}
                                    {ascRequired ? "*" : null}
                                </InputLabel>
                                <Select
                                    {...field}
                                    inputProps={{
                                        "data-testid": "form-component-diabetesSelect", // Aggiungi data-testid qui
                                    }}
                                    label={lang.scales.urineAnalyzerComponent.asc.label}
                                    error={!!fieldState.error}
                                >
                                    {AscConstant.possibleValue.map((data, index) => (
                                        <MenuItem value={data.mark}>
                                            <Stack
                                                width={"100%"}
                                                justifyContent={"space-between"}
                                                flexDirection={"row"}
                                            >
                                                <Box>{data.mark}</Box>
                                                <Box>
                                                    {showMarkInterval(
                                                        index,
                                                        AscConstant.possibleValue
                                                    )}
                                                </Box>
                                            </Stack>
                                        </MenuItem>
                                    ))}
                                </Select>
                                {fieldState.error && <p>{fieldState.error.message}</p>}
                            </FormControl>
                        )}
                    />

                    <Controller
                        name="leu"
                        control={control}
                        rules={{
                            required: leuRequired
                                ? lang.scales.urineAnalyzerComponent.errorText
                                : undefined,
                        }}
                        render={({ field, fieldState }) => (
                            <FormControl fullWidth>
                                <InputLabel>
                                    {lang.scales.urineAnalyzerComponent.leu.label}
                                    {leuRequired ? "*" : null}
                                </InputLabel>
                                <Select
                                    {...field}
                                    inputProps={{
                                        "data-testid": "form-component-diabetesSelect", // Aggiungi data-testid qui
                                    }}
                                    label={lang.scales.urineAnalyzerComponent.leu.label}
                                    error={!!fieldState.error}
                                >
                                    {LeuConstant.possibleValue.map((data, index) => (
                                        <MenuItem value={data.mark}>
                                            <Stack
                                                width={"100%"}
                                                justifyContent={"space-between"}
                                                flexDirection={"row"}
                                            >
                                                <Box>{data.mark}</Box>
                                                <Box>
                                                    {showMarkInterval(
                                                        index,
                                                        LeuConstant.possibleValue
                                                    )}
                                                </Box>
                                            </Stack>
                                        </MenuItem>
                                    ))}
                                </Select>
                                {fieldState.error && <p>{fieldState.error.message}</p>}
                            </FormControl>
                        )}
                    />

                    <Controller
                        name="glu"
                        control={control}
                        rules={{
                            required: gluRequired
                                ? lang.scales.urineAnalyzerComponent.errorText
                                : undefined,
                        }}
                        render={({ field, fieldState }) => (
                            <FormControl fullWidth>
                                <InputLabel>
                                    {lang.scales.urineAnalyzerComponent.glu.label}
                                    {gluRequired ? "*" : null}
                                </InputLabel>
                                <Select
                                    {...field}
                                    inputProps={{
                                        "data-testid": "form-component-diabetesSelect", // Aggiungi data-testid qui
                                    }}
                                    label={lang.scales.urineAnalyzerComponent.glu.label}
                                    error={!!fieldState.error}
                                >
                                    {GluConstant.possibleValue.map((data, index) => (
                                        <MenuItem value={data.mark}>
                                            <Stack
                                                width={"100%"}
                                                justifyContent={"space-between"}
                                                flexDirection={"row"}
                                            >
                                                <Box>{data.mark}</Box>
                                                <Box>
                                                    {showMarkInterval(
                                                        index,
                                                        GluConstant.possibleValue
                                                    )}
                                                </Box>
                                            </Stack>
                                        </MenuItem>
                                    ))}
                                </Select>
                                {fieldState.error && <p>{fieldState.error.message}</p>}
                            </FormControl>
                        )}
                    />

                    <Controller
                        name="alb"
                        control={control}
                        rules={{
                            required: albRequired
                                ? lang.scales.urineAnalyzerComponent.errorText
                                : undefined,
                        }}
                        render={({ field, fieldState }) => (
                            <FormControl fullWidth>
                                <InputLabel>
                                    {lang.scales.urineAnalyzerComponent.alb.label}
                                    {gluRequired ? "*" : null}
                                </InputLabel>
                                <Select
                                    {...field}
                                    inputProps={{
                                        "data-testid": "form-component-diabetesSelect", // Aggiungi data-testid qui
                                    }}
                                    label={lang.scales.urineAnalyzerComponent.alb.label}
                                    error={!!fieldState.error}
                                >
                                    {AlbConstant.possibleValue.map((data, index) => (
                                        <MenuItem value={data.mark}>
                                            <Stack
                                                width={"100%"}
                                                justifyContent={"space-between"}
                                                flexDirection={"row"}
                                            >
                                                <Box>{data.mark}</Box>
                                                <Box>
                                                    {showMarkInterval(
                                                        index,
                                                        AlbConstant.possibleValue
                                                    )}
                                                </Box>
                                            </Stack>
                                        </MenuItem>
                                    ))}
                                </Select>
                                {fieldState.error && <p>{fieldState.error.message}</p>}
                            </FormControl>
                        )}
                    />

                    <Controller
                        name="bil"
                        control={control}
                        rules={{
                            required: bilRequired
                                ? lang.scales.urineAnalyzerComponent.errorText
                                : undefined,
                        }}
                        render={({ field, fieldState }) => (
                            <FormControl fullWidth>
                                <InputLabel>
                                    {lang.scales.urineAnalyzerComponent.bil.label}
                                    {gluRequired ? "*" : null}
                                </InputLabel>
                                <Select
                                    {...field}
                                    inputProps={{
                                        "data-testid": "form-component-diabetesSelect", // Aggiungi data-testid qui
                                    }}
                                    label={lang.scales.urineAnalyzerComponent.bil.label}
                                    error={!!fieldState.error}
                                >
                                    {BilConstant.possibleValue.map((data, index) => (
                                        <MenuItem value={data.mark}>
                                            <Stack
                                                width={"100%"}
                                                justifyContent={"space-between"}
                                                flexDirection={"row"}
                                            >
                                                <Box>{data.mark}</Box>
                                                <Box>
                                                    {showMarkInterval(
                                                        index,
                                                        BilConstant.possibleValue
                                                    )}
                                                </Box>
                                            </Stack>
                                        </MenuItem>
                                    ))}
                                </Select>
                                {fieldState.error && <p>{fieldState.error.message}</p>}
                            </FormControl>
                        )}
                    />

                    <Controller
                        name="ph"
                        control={control}
                        rules={{
                            required: phRequired
                                ? lang.scales.urineAnalyzerComponent.errorText
                                : undefined,
                        }}
                        render={({ field, fieldState }) => (
                            <FormControl fullWidth>
                                <InputLabel>
                                    {lang.scales.urineAnalyzerComponent.ph.label}
                                    {gluRequired ? "*" : null}
                                </InputLabel>
                                <Select
                                    {...field}
                                    inputProps={{
                                        "data-testid": "form-component-diabetesSelect", // Aggiungi data-testid qui
                                    }}
                                    label={lang.scales.urineAnalyzerComponent.ph.label}
                                    error={!!fieldState.error}
                                >
                                    {PhConstant.possibleValue.map((data, index) => (
                                        <MenuItem value={data.mark}>
                                            <Stack
                                                width={"100%"}
                                                justifyContent={"space-between"}
                                                flexDirection={"row"}
                                            >
                                                <Box>{data.mark}</Box>
                                                <Box>
                                                    {showMarkInterval(
                                                        index,
                                                        PhConstant.possibleValue
                                                    )}
                                                </Box>
                                            </Stack>
                                        </MenuItem>
                                    ))}
                                </Select>
                                {fieldState.error && <p>{fieldState.error.message}</p>}
                            </FormControl>
                        )}
                    />

                    <Controller
                        name="sg"
                        control={control}
                        rules={{
                            required: sgRequired
                                ? lang.scales.urineAnalyzerComponent.errorText
                                : undefined,
                        }}
                        render={({ field, fieldState }) => (
                            <FormControl fullWidth>
                                <InputLabel>
                                    {lang.scales.urineAnalyzerComponent.sg.label}
                                    {gluRequired ? "*" : null}
                                </InputLabel>
                                <Select
                                    {...field}
                                    inputProps={{
                                        "data-testid": "form-component-diabetesSelect", // Aggiungi data-testid qui
                                    }}
                                    label={lang.scales.urineAnalyzerComponent.sg.label}
                                    error={!!fieldState.error}
                                >
                                    {SgConstant.possibleValue.map((data, index) => (
                                        <MenuItem value={data.mark}>
                                            <Stack
                                                width={"100%"}
                                                justifyContent={"space-between"}
                                                flexDirection={"row"}
                                            >
                                                <Box>{data.mark}</Box>
                                                <Box>
                                                    {showMarkInterval(
                                                        index,
                                                        SgConstant.possibleValue
                                                    )}
                                                </Box>
                                            </Stack>
                                        </MenuItem>
                                    ))}
                                </Select>
                                {fieldState.error && <p>{fieldState.error.message}</p>}
                            </FormControl>
                        )}
                    />

                    <Controller
                        name="ket"
                        control={control}
                        rules={{
                            required: ketRequired
                                ? lang.scales.urineAnalyzerComponent.errorText
                                : undefined,
                        }}
                        render={({ field, fieldState }) => (
                            <FormControl fullWidth>
                                <InputLabel>
                                    {lang.scales.urineAnalyzerComponent.ket.label}
                                    {gluRequired ? "*" : null}
                                </InputLabel>
                                <Select
                                    {...field}
                                    inputProps={{
                                        "data-testid": "form-component-diabetesSelect", // Aggiungi data-testid qui
                                    }}
                                    label={lang.scales.urineAnalyzerComponent.ket.label}
                                    error={!!fieldState.error}
                                >
                                    {KetConstant.possibleValue.map((data, index) => (
                                        <MenuItem value={data.mark}>
                                            <Stack
                                                width={"100%"}
                                                justifyContent={"space-between"}
                                                flexDirection={"row"}
                                            >
                                                <Box>{data.mark}</Box>
                                                <Box>
                                                    {showMarkInterval(
                                                        index,
                                                        KetConstant.possibleValue
                                                    )}
                                                </Box>
                                            </Stack>
                                        </MenuItem>
                                    ))}
                                </Select>
                                {fieldState.error && <p>{fieldState.error.message}</p>}
                            </FormControl>
                        )}
                    />

                    <Controller
                        name="pro"
                        control={control}
                        rules={{
                            required: proRequired
                                ? lang.scales.urineAnalyzerComponent.errorText
                                : undefined,
                        }}
                        render={({ field, fieldState }) => (
                            <FormControl fullWidth>
                                <InputLabel>
                                    {lang.scales.urineAnalyzerComponent.pro.label}
                                    {gluRequired ? "*" : null}
                                </InputLabel>
                                <Select
                                    {...field}
                                    inputProps={{
                                        "data-testid": "form-component-diabetesSelect", // Aggiungi data-testid qui
                                    }}
                                    label={lang.scales.urineAnalyzerComponent.pro.label}
                                    error={!!fieldState.error}
                                >
                                    {ProConstant.possibleValue.map((data, index) => (
                                        <MenuItem value={data.mark}>
                                            <Stack
                                                width={"100%"}
                                                justifyContent={"space-between"}
                                                flexDirection={"row"}
                                            >
                                                <Box>{data.mark}</Box>
                                                <Box>
                                                    {showMarkInterval(
                                                        index,
                                                        ProConstant.possibleValue
                                                    )}
                                                </Box>
                                            </Stack>
                                        </MenuItem>
                                    ))}
                                </Select>
                                {fieldState.error && <p>{fieldState.error.message}</p>}
                            </FormControl>
                        )}
                    />

                    <Controller
                        name="nit"
                        control={control}
                        rules={{
                            required: nitRequired
                                ? lang.scales.urineAnalyzerComponent.errorText
                                : undefined,
                        }}
                        render={({ field, fieldState }) => (
                            <FormControl fullWidth>
                                <InputLabel>
                                    {lang.scales.urineAnalyzerComponent.nit.label}
                                    {gluRequired ? "*" : null}
                                </InputLabel>
                                <Select
                                    {...field}
                                    inputProps={{
                                        "data-testid": "form-component-diabetesSelect", // Aggiungi data-testid qui
                                    }}
                                    label={lang.scales.urineAnalyzerComponent.nit.label}
                                    error={!!fieldState.error}
                                >
                                    {NitConstant.possibleValue.map((data, index) => (
                                        <MenuItem value={data.mark}>
                                            <Stack
                                                width={"100%"}
                                                justifyContent={"space-between"}
                                                flexDirection={"row"}
                                            >
                                                <Box>{data.mark}</Box>
                                                <Box>
                                                    {showMarkInterval(
                                                        index,
                                                        NitConstant.possibleValue
                                                    )}
                                                </Box>
                                            </Stack>
                                        </MenuItem>
                                    ))}
                                </Select>
                                {fieldState.error && <p>{fieldState.error.message}</p>}
                            </FormControl>
                        )}
                    />

                    <Controller
                        name="uro"
                        control={control}
                        rules={{
                            required: uroRequired
                                ? lang.scales.urineAnalyzerComponent.errorText
                                : undefined,
                        }}
                        render={({ field, fieldState }) => (
                            <FormControl fullWidth>
                                <InputLabel>
                                    {lang.scales.urineAnalyzerComponent.uro.label}
                                    {gluRequired ? "*" : null}
                                </InputLabel>
                                <Select
                                    {...field}
                                    inputProps={{
                                        "data-testid": "form-component-diabetesSelect", // Aggiungi data-testid qui
                                    }}
                                    label={lang.scales.urineAnalyzerComponent.uro.label}
                                    error={!!fieldState.error}
                                >
                                    {UroConstant.possibleValue.map((data, index) => (
                                        <MenuItem value={data.mark}>
                                            <Stack
                                                width={"100%"}
                                                justifyContent={"space-between"}
                                                flexDirection={"row"}
                                            >
                                                <Box>{data.mark}</Box>
                                                <Box>
                                                    {showMarkInterval(
                                                        index,
                                                        UroConstant.possibleValue
                                                    )}
                                                </Box>
                                            </Stack>
                                        </MenuItem>
                                    ))}
                                </Select>
                                {fieldState.error && <p>{fieldState.error.message}</p>}
                            </FormControl>
                        )}
                    />

                    <Controller
                        name="cre"
                        control={control}
                        rules={{
                            required: creRequired
                                ? lang.scales.urineAnalyzerComponent.errorText
                                : undefined,
                        }}
                        render={({ field, fieldState }) => (
                            <FormControl fullWidth>
                                <InputLabel>
                                    {lang.scales.urineAnalyzerComponent.cre.label}
                                    {gluRequired ? "*" : null}
                                </InputLabel>
                                <Select
                                    {...field}
                                    inputProps={{
                                        "data-testid": "form-component-diabetesSelect", // Aggiungi data-testid qui
                                    }}
                                    label={lang.scales.urineAnalyzerComponent.cre.label}
                                    error={!!fieldState.error}
                                >
                                    {CreConstant.possibleValue.map((data, index) => (
                                        <MenuItem value={data.mark}>
                                            <Stack
                                                width={"100%"}
                                                justifyContent={"space-between"}
                                                flexDirection={"row"}
                                            >
                                                <Box>{data.mark}</Box>
                                                <Box>
                                                    {showMarkInterval(
                                                        index,
                                                        CreConstant.possibleValue
                                                    )}
                                                </Box>
                                            </Stack>
                                        </MenuItem>
                                    ))}
                                </Select>
                                {fieldState.error && <p>{fieldState.error.message}</p>}
                            </FormControl>
                        )}
                    />

                    <Controller
                        name="blo"
                        control={control}
                        rules={{
                            required: bloRequired
                                ? lang.scales.urineAnalyzerComponent.errorText
                                : undefined,
                        }}
                        render={({ field, fieldState }) => (
                            <FormControl fullWidth>
                                <InputLabel>
                                    {lang.scales.urineAnalyzerComponent.blo.label}
                                    {gluRequired ? "*" : null}
                                </InputLabel>
                                <Select
                                    {...field}
                                    inputProps={{
                                        "data-testid": "form-component-diabetesSelect", // Aggiungi data-testid qui
                                    }}
                                    label={lang.scales.urineAnalyzerComponent.blo.label}
                                    error={!!fieldState.error}
                                >
                                    {BloConstant.possibleValue.map((data, index) => (
                                        <MenuItem value={data.mark}>
                                            <Stack
                                                width={"100%"}
                                                justifyContent={"space-between"}
                                                flexDirection={"row"}
                                            >
                                                <Box>{data.mark}</Box>
                                                <Box>
                                                    {showMarkInterval(
                                                        index,
                                                        BloConstant.possibleValue
                                                    )}
                                                </Box>
                                            </Stack>
                                        </MenuItem>
                                    ))}
                                </Select>
                                {fieldState.error && <p>{fieldState.error.message}</p>}
                            </FormControl>
                        )}
                    />

                    <Controller
                        name="ca"
                        control={control}
                        rules={{
                            required: caRequired
                                ? lang.scales.urineAnalyzerComponent.errorText
                                : undefined,
                        }}
                        render={({ field, fieldState }) => (
                            <FormControl fullWidth>
                                <InputLabel>
                                    {lang.scales.urineAnalyzerComponent.ca.label}
                                    {gluRequired ? "*" : null}
                                </InputLabel>
                                <Select
                                    {...field}
                                    inputProps={{
                                        "data-testid": "form-component-diabetesSelect", // Aggiungi data-testid qui
                                    }}
                                    label={lang.scales.urineAnalyzerComponent.ca.label}
                                    error={!!fieldState.error}
                                >
                                    {CaConstant.possibleValue.map((data, index) => (
                                        <MenuItem value={data.mark}>
                                            <Stack
                                                width={"100%"}
                                                justifyContent={"space-between"}
                                                flexDirection={"row"}
                                            >
                                                <Box>{data.mark}</Box>
                                                <Box>
                                                    {showMarkInterval(
                                                        index,
                                                        CaConstant.possibleValue
                                                    )}
                                                </Box>
                                            </Stack>
                                        </MenuItem>
                                    ))}
                                </Select>
                                {fieldState.error && <p>{fieldState.error.message}</p>}
                            </FormControl>
                        )}
                    />
                </Stack>
            )}

            <Popover
                open={infoTextIsOpen}
                anchorEl={anchorEl}
                onClose={() => {
                    setInfoTextIsOpen(false);
                }}
                disableScrollLock
                transformOrigin={{
                    horizontal: "left",
                    vertical: "bottom",
                }}
            >
                <Typography sx={{ p: 2 }}>{infoText}</Typography>
            </Popover>
        </Stack>
    );
}

export default UrineAnalyzerData;
