import { useFormContext } from "react-hook-form";
import { TextField, Box, Stack, InputAdornment } from "@mui/material";
import { useTranslatedMessages } from "@/multiLanguage/i18n";

interface InputInfoBodyDataProps {
    heightRequired?: boolean;
    weightRequired?: boolean;
}
function InfoBodyData({ heightRequired = true, weightRequired = true }: InputInfoBodyDataProps) {
    const { register, formState } = useFormContext();
    const lang = useTranslatedMessages();

    return (
        <Stack gap={3}>
            <Box>
                <TextField
                    {...register("height", {
                        valueAsNumber: true,
                        required: {
                            value: heightRequired,
                            message: heightRequired
                                ? lang.scales.infoBodyComponent.height.errorText
                                : "",
                        },
                        validate: (value) => {
                            if (!value) {
                                return true;
                            }
                            if (!Number.isInteger(value) || value < 60 || value > 220) {
                                return lang.scales.infoBodyComponent.height.errorText;
                            }
                            return true;
                        },
                    })}
                    inputProps={{
                        "data-testid": "form-component-height",
                        step: ".01",
                        onWheel: (event: any) => {
                            event.target.blur();
                        },
                    }}
                    label={
                        <>
                            {lang.scales.infoBodyComponent.height.label}
                            {heightRequired ? "*" : null}
                        </>
                    }
                    fullWidth
                    type="number"
                    error={!!formState.errors.height}
                    helperText={
                        formState.errors.height ? String(formState.errors.height.message) : ""
                    }
                    InputProps={{
                        endAdornment: <InputAdornment position="end">cm</InputAdornment>,
                    }}
                />
            </Box>
            <Box>
                <TextField
                    {...register("weight", {
                        valueAsNumber: true,
                        required: {
                            value: weightRequired,
                            message: weightRequired
                                ? lang.scales.infoBodyComponent.weight.errorText
                                : "",
                        },
                        validate: (value) => {
                            if (!value) {
                                return true;
                            }
                            if (!Number.isInteger(value) || value < 30 || value > 200) {
                                return lang.scales.infoBodyComponent.weight.errorText;
                            }
                            return true;
                        },
                    })}
                    inputProps={{
                        "data-testid": "form-component-weight",
                        step: ".01",
                        onWheel: (event: any) => {
                            event.target.blur();
                        },
                    }}
                    label={
                        <>
                            {lang.scales.infoBodyComponent.weight.label}
                            {weightRequired ? "*" : null}
                        </>
                    }
                    type="number"
                    fullWidth
                    error={!!formState.errors.weight}
                    helperText={
                        formState.errors.weight ? String(formState.errors.weight.message) : ""
                    }
                    InputProps={{
                        endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                    }}
                />
            </Box>
        </Stack>
    );
}

export default InfoBodyData;
