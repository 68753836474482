import { useFormContext } from "react-hook-form";
import { TextField, Box, Stack, InputAdornment } from "@mui/material";
import { useTranslatedMessages } from "@/multiLanguage/i18n";

interface InputProps {
    triglycerides?: boolean; //indica se si deve vedere o meno il campo triglycerides
}

function CholesterolData({ triglycerides = true }: InputProps) {
    const {
        register,
        formState: { errors },
    } = useFormContext();
    const lang = useTranslatedMessages();

    return (
        <Stack gap={3}>
            {/* Qui puoi inserire altri componenti del form se necessario */}

            <Box>
                <TextField
                    {...register("totalCholesterol", {
                        valueAsNumber: true,
                        required: lang.scales.cholesterolComponent.totalCholesterol.errorText,
                        validate: (value) => {
                            if (!Number.isInteger(value) || value < 1 || value > 348) {
                                return lang.scales.cholesterolComponent.totalCholesterol.errorText;
                            } else {
                                return true;
                            }
                        },
                    })}
                    data-testid="form-component-totalCholesterol"
                    label={<>{lang.scales.cholesterolComponent.totalCholesterol.label}*</>}
                    type="number"
                    inputProps={{
                        step: ".01",
                        onWheel: (event: any) => {
                            event.target.blur();
                        },
                    }}
                    fullWidth
                    error={!!errors.totalCholesterol}
                    helperText={
                        errors.totalCholesterol
                            ? String(errors.totalCholesterol?.message)
                            : lang.scales.cholesterolComponent.totalCholesterol.hintText
                    }
                    InputProps={{
                        endAdornment: <InputAdornment position="end">mg/dL</InputAdornment>,
                    }}
                />
            </Box>
            <Box>
                <TextField
                    {...register("hdlCholesterol", {
                        valueAsNumber: true,
                        required: lang.scales.cholesterolComponent.hdlCholesterol.errorText,
                        validate: (value) => {
                            if (!Number.isInteger(value) || value < 0 || value > 150) {
                                return lang.scales.cholesterolComponent.hdlCholesterol.errorText;
                            } else {
                                return true;
                            }
                        },
                    })}
                    data-testid="form-component-hdlCholesterol"
                    label={<>{lang.scales.cholesterolComponent.hdlCholesterol.label}*</>}
                    type="number"
                    inputProps={{
                        step: ".01",
                        onWheel: (event: any) => {
                            event.target.blur();
                        },
                    }}
                    fullWidth
                    error={!!errors.hdlCholesterol}
                    helperText={errors.hdlCholesterol ? String(errors.hdlCholesterol?.message) : ""}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">mg/dL</InputAdornment>,
                    }}
                />
            </Box>

            {triglycerides && (
                <Box>
                    <TextField
                        {...register("triglycerides", {
                            valueAsNumber: true,
                            required: lang.scales.cholesterolComponent.triglycerides.errorText,
                            validate: (value) => {
                                if (!Number.isInteger(value) || value < 7 || value > 13975) {
                                    return lang.scales.cholesterolComponent.triglycerides.errorText;
                                } else {
                                    return true;
                                }
                            },
                        })}
                        data-testid="form-component-triglycerides"
                        label={<>{lang.scales.cholesterolComponent.triglycerides.label}*</>}
                        type="number"
                        inputProps={{
                            step: ".01",
                            onWheel: (event: any) => {
                                event.target.blur();
                            },
                        }}
                        fullWidth
                        error={!!errors.triglycerides}
                        helperText={
                            errors.triglycerides ? String(errors.triglycerides?.message) : ""
                        }
                        InputProps={{
                            endAdornment: <InputAdornment position="end">mg/dL</InputAdornment>,
                        }}
                    />
                </Box>
            )}
        </Stack>
    );
}

export default CholesterolData;
