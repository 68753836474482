import { RiskLevels, returnValue } from "./shared";
import { FormDataGlycated } from "../reactHookGlycatedScale";
import {
    AcrRiskCategory,
    AnalyzerPossibleValueProps,
    ProjectAnalyzerDataProps,
    acrMatrix,
    albuminThresholds,
    creatinineThresholds,
    percentAndMmolCorrelation,
} from "./constants";

export type ScaleGlycatedIndex = {
    score2: number;
    countryRisk: string;
    egfr: number | null;
    smoke: boolean;
    riskLevel: string;
    bmi: number | null;
    eventProbability: string;
    glycatedDoubleValue?: any;
};

export type ScaleGlycatedFormData = {
    name?: string;
    surname?: string;
    country: string;
    height?: number;
    weight?: number;
    actualSmoker: boolean;
    exSmoker: boolean;
    exSmokerYears: number;
    exSmokerSwitch: boolean;
    totalCholesterol: number;
    hdlCholesterol: number;
    sex: string;
    creatinine?: number;
    age: number;
    cardiovascularEvent: boolean;
    cardiovascularEventSwitch: boolean;
    moreCardiovascularEvent: boolean;
    systolicPressure: number;
    diastolicPressure?: number;
    glycated: number;
    diabetesSwitch: number | undefined;
};

export interface valueUomFieldGliecatedProperty {
    value: number;
    uom: string;
}
export interface MapGlycatedValue {
    p?: number;
    mmol?: number;
}
export function getGlycatedValue(glycatedField: valueUomFieldGliecatedProperty): MapGlycatedValue {
    function convertPtoMmol(data: MapGlycatedValue[], pValue: any): number | undefined {
        pValue = parseFloat(pValue);
        const roundedPValue = parseFloat(pValue.toFixed(1));
        const record = data.find((entry) => entry.p === roundedPValue);
        return record ? Math.round(record.mmol!) : undefined;
    }

    function convertMmolToP(data: MapGlycatedValue[], mmolValue: number): number | undefined {
        const roundedMmolValue = Math.round(mmolValue);
        const record = data.find((entry) => entry.mmol === roundedMmolValue);
        return record ? parseFloat(record.p!.toFixed(1)) : undefined;
    }

    let glycatedDoubleValue: MapGlycatedValue = { p: undefined, mmol: undefined };
    if (glycatedField.uom === "%") {
        let mmolValue = convertPtoMmol(percentAndMmolCorrelation, glycatedField.value);
        if (mmolValue) {
            glycatedDoubleValue = { p: glycatedField.value, mmol: mmolValue };
        }
    } else {
        let pValue = convertMmolToP(percentAndMmolCorrelation, glycatedField.value);
        if (pValue) {
            glycatedDoubleValue = { p: pValue, mmol: glycatedField.value };
        }
    }
    return glycatedDoubleValue;
}

export function getRiskLevelValue(
    formData: FormDataGlycated,
    score2value: number,
    unitOfMeasure: any,
    egfr: number | null
): [keyof typeof RiskLevels, number] {
    const diabetes2 = formData.glycatedValue! > (unitOfMeasure === "mmol/mol" ? 48 : 6.5);
    //Ha avuto più di due eventi cardiovascolari in due anni consecutivi
    if (formData.cardiovascularEventSwitch && formData.moreCardiovascularEvent) {
        return returnValue("extreme");
    }
    if (
        // Ha avuto almeno un evento cardiovascolare
        formData.cardiovascularEventSwitch ||
        //score2
        (score2value >= 7.5 && formData.age < 50) ||
        (score2value >= 10 && formData.age >= 50 && formData.age < 70) ||
        (score2value >= 15 && formData.age >= 70) ||
        //MRC severa
        (egfr && egfr < 30) ||
        //DM2 con danno d'organo
        (diabetes2 && egfr && egfr < 45)
    ) {
        return returnValue("veryHigh");
    }
    if (
        //score2
        (score2value >= 2.5 && score2value < 7.5 && formData.age < 50) ||
        (score2value >= 5 && score2value < 10 && formData.age >= 50 && formData.age < 70) ||
        (score2value >= 7.5 && score2value < 15 && formData.age >= 70) ||
        //col elevati o pressione alta
        formData.totalCholesterol > 310 ||
        (formData.systolicPressure > 179 &&
            formData.diastolicPressure &&
            formData.diastolicPressure > 109) ||
        //condizione imposta da sarzani in call 26/01
        diabetes2
    ) {
        return returnValue("high");
    }
    if (
        (score2value < 2.5 && formData.age < 50) ||
        (score2value < 5 && formData.age >= 50 && formData.age < 70) ||
        (score2value < 7.5 && formData.age >= 70)
    ) {
        return returnValue("moderateLow");
    }
    //TODO: come gestire l'errore?
    return ["moderateLow", -1];
}

export function getSpecificRecommendation(
    formData: FormDataGlycated,
    scaleIndexes: ScaleGlycatedIndex,
    lang: any
) {
    const scaleGlycated = lang.pdf.scales.scaleGlycated;
    if (formData.diabetesSwitch) {
        if (formData.age < 80) {
            if (scaleIndexes.glycatedDoubleValue.p < 7) {
                return scaleGlycated.recommendations.optimalGlycaemicControl;
            } else {
                return scaleGlycated.recommendations.nonOptimalGlycaemicControl;
            }
        } else {
            return scaleGlycated.recommendations.over80GlycaemicControl;
        }
    } else {
        if (scaleIndexes.glycatedDoubleValue.p >= 6.5) {
            return scaleGlycated.recommendations.possibleDiagnosiOfDiabetesMellitus;
        } else {
            return "";
        }
    }
}

export const showMarkInterval = (index: number, markValues: AnalyzerPossibleValueProps[]) => {
    if (index === markValues.length - 1) {
        return (
            "≥" +
            markValues[index].concentrationValue +
            (markValues[index].concetrationUom ? markValues[index].concetrationUom : "")
        );
    } else {
        return `(${markValues[index].concentrationValue} ${
            markValues[index].concetrationUom ? markValues[index].concetrationUom : ""
        } - ${markValues[index + 1].concentrationValue} ${
            markValues[index + 1].concetrationUom ? markValues[index + 1].concetrationUom : ""
        })`;
    }
};

// Function to determine the ACR category based on the matrix.
export function determineAcrCategory(albumin: number, creatinine: number): AcrRiskCategory {
    // Find the index for albumin and creatinine thresholds.
    const albuminIndex = albuminThresholds.findIndex((threshold) => albumin <= threshold);
    const creatinineIndex = creatinineThresholds.findIndex((threshold) => creatinine <= threshold);
    // If the indices are valid, return the corresponding category from the matrix.
    if (albuminIndex !== -1 && creatinineIndex !== -1) {
        return acrMatrix[albuminIndex][creatinineIndex];
    }
    // Default return value if the inputs don't match any thresholds.
    return AcrRiskCategory.Recheck;
}

export const findValueByMark = (inputMark: string, values: ProjectAnalyzerDataProps) => {
    return values.possibleValue.find((oggetto) => oggetto.mark === inputMark);
};
