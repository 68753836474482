import React, { useEffect, useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
    DialogActions,
    DialogContent,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Dialog,
    useTheme,
    FormHelperText,
    Stack,
    Autocomplete,
    TextField,
    Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { CustomButton } from "../components";
import { DialogProps } from ".";
import { useTranslatedMessages } from "@/multiLanguage/i18n";
import { useLocalStorageService } from "@/states/localStorageState";
import { ClientData, getClients } from "@/services/pharmacyService";
import { DialogHeaderComponent } from "@/dialogs/DialogHeaderComponent";
import { useScaleStore } from "@/states/formState";
interface FormValues {
    selectedClient: string;
    selectedScale: string;
}

export interface ScaleDialogProps extends DialogProps {
    client?: ClientData;
}

export function ScaleCompilateDialog({ isOpen, closeDialog, client }: ScaleDialogProps) {
    const lang = useTranslatedMessages();
    const { palette } = useTheme();
    const navigate = useNavigate();
    const scales = [
        { key: "scaleFree", name: " SCORE2-based integrata" },
        { key: "lopreHolder", name: "SCORE2-based con eGFR" },
        { key: "scaleGlycated", name: "SCORE2-based con HbA1c" },
    ];
    const user = useLocalStorageService((state) => state.user);
    const setSelectedScale = useScaleStore((state) => state.setSelectedScale);
    const [clients, setClients] = React.useState<ClientData[]>([]);
    const hasInitialized = useRef(false);
    const [filteredClients, setFilteredClients] = useState<ClientData[]>([]);
    const {
        control,
        reset,
        setValue,
        handleSubmit,
        formState: { errors },
    } = useForm({
        mode: "onChange",
        defaultValues: {
            selectedClient: "",
            selectedScale: "", // Puoi impostare un valore di default se necessario
        },
    });

    useEffect(() => {
        if (client) {
            setClients([client]);
            setFilteredClients([client]);
            setValue("selectedClient", client.id!, { shouldValidate: true });
        } else if (!hasInitialized.current) {
            const fetchData = async () => {
                try {
                    const clientsData = await getClients(user?.pharmacyId!);
                    setClients(clientsData);
                    setFilteredClients(clientsData);
                } catch (error) {
                    console.error("Errore nel recupero delle compilazioni recenti", error);
                }
            };
            fetchData();
        }
        hasInitialized.current = true;
    }, [client, user?.pharmacyId]);

    const resetForm = () => {
        reset({
            selectedScale: "",
            selectedClient: client ? client.id : "",
        });
    };

    /*  useEffect(() => {
         if (clients.length > 0 && client) {
             resetForm()
         }
     }, [clients, client]); */

    const onSubmit = (data: FormValues) => {
        setSelectedScale(data.selectedScale!);
        //const selectedClient = clients.find(client => client.id === data.selectedClient);
        navigate(`/scale/${data.selectedScale}`, {
            state: { client: client ? client : data.selectedClient },
        });
        window.scrollTo(0, 0);
    };

    // Funzione per filtrare i clienti in base al nome e al cognome
    const filterClients = (searchTerm: string) => {
        const filtered = clients.filter(
            (client) =>
                client.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                client.surname.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredClients(filtered);
    };

    return (
        <Dialog
            maxWidth="xs"
            open={isOpen}
            onClose={() => {
                closeDialog();
                resetForm();
            }}
            PaperProps={{ style: { borderRadius: 0, minWidth: "15rem" } }}
        >
            <DialogHeaderComponent title={lang.pages.reservedArea.addCompilationDialogHeader} />

            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogContent
                    sx={{ paddingLeft: "2rem", paddingRight: "2rem", paddingBottom: "0rem" }}
                >
                    <Stack sx={{ paddingBottom: "1rem" }}>
                        <Typography variant="h5">
                            {lang.pages.reservedArea.addCompilationDialogClientHelp}{" "}
                        </Typography>
                    </Stack>

                    <Controller
                        name="selectedClient"
                        control={control}
                        render={({ field }) => (
                            <Autocomplete
                                disabled={client ? true : false}
                                options={filteredClients}
                                value={client ? client : undefined}
                                getOptionLabel={(client) =>
                                    `${client.name} ${client.surname} - ${client.dateOfBirth}`
                                }
                                renderOption={(props, client) => (
                                    <li {...props}>
                                        <Stack
                                            width={"100%"}
                                            direction="row"
                                            alignItems="center"
                                            justifyContent="space-between"
                                        >
                                            <div>{`${client.name} ${client.surname}`}</div>
                                            <div>{client.dateOfBirth.toString()}</div>
                                        </Stack>
                                    </li>
                                )}
                                onInputChange={(_, newInputValue) => {
                                    field.onChange(newInputValue);
                                    filterClients(newInputValue);
                                }}
                                onChange={(_, newValue) => {
                                    field.onChange(newValue); // Imposta l'oggetto selezionato
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={lang.pages.reservedArea.addCompilationDialogClient}
                                        variant="outlined"
                                        fullWidth
                                    />
                                )}
                            />
                        )}
                    />

                    <FormControl fullWidth variant="outlined" margin="normal">
                        <InputLabel>
                            {lang.pages.reservedArea.addCompilationDialogScale}*
                        </InputLabel>

                        <Controller
                            name="selectedScale"
                            control={control}
                            rules={{ required: "Scale is required" }}
                            render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                <Select
                                    labelId="scale-label"
                                    value={value ? value : ""}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    inputRef={ref}
                                    error={!!errors.selectedScale}
                                    label={
                                        <>{lang.pages.reservedArea.addCompilationDialogScale}*</>
                                    }
                                >
                                    {scales.map((name) => (
                                        <MenuItem key={name.key} value={name.key}>
                                            {name.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            )}
                        />
                        {errors.selectedScale && (
                            <FormHelperText error>{errors.selectedScale.message}</FormHelperText>
                        )}
                    </FormControl>
                </DialogContent>
                <DialogActions
                    sx={{ paddingLeft: "2rem", paddingRight: "2rem", paddingTop: "0rem" }}
                >
                    <Stack width={"100%"} flexDirection={"row"} gap={1}>
                        <CustomButton
                            sx={{
                                width: "100%",
                                borderColor: palette.secondary.dark,
                                border: "1px solid",
                                backgroundColor: palette.primary.light,
                                color: palette.secondary.dark,
                            }}
                            label={lang.shared.close}
                            secondary
                            onClick={() => {
                                closeDialog();
                                resetForm();
                            }}
                        />
                        <CustomButton
                            sx={{
                                width: "100%",
                                color: palette.primary.light,
                                backgroundColor: palette.secondary.dark,
                            }}
                            label={lang.pages.reservedArea.addCompilationDialogButton}
                            type="submit"
                            /* disabled={!isValid} */
                            color="primary"
                        />
                    </Stack>
                </DialogActions>
            </form>
        </Dialog>
    );
}
