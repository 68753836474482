import { useFormContext } from "react-hook-form";
import { TextField, FormControlLabel, Stack, Switch } from "@mui/material";
import { useTranslatedMessages } from "@/multiLanguage/i18n";

import { useEffect } from "react";

function AlbuminuriaData() {
    const lang = useTranslatedMessages();
    const {
        watch,
        setValue,
        register,
        formState: { errors },
    } = useFormContext();

    const isAlbuminuriaSwitch = watch("albuminuriaSwitch");

    useEffect(() => {
        if (!isAlbuminuriaSwitch) {
            setValue("acr", 0);
        }
    }, [isAlbuminuriaSwitch, setValue]);
    // Funzione per gestire il cambio del valore in base al campo dipendente

    return (
        <Stack gap={3}>
            {/* <Typography variant='h4'>{lang.scales.urineComponent.title} </Typography>
             */}

            <FormControlLabel
                control={
                    <Switch
                        {...register("albuminuriaSwitch")}
                        data-testid="form-component-albuminuriaSwitch"
                        /*  onChange={(e) => setValue("albuminuriaSwitch", e.target.checked)} */
                    />
                }
                label={lang.scales.urineComponent.albuminuria.label}
                labelPlacement="start"
                sx={{
                    marginLeft: 0,
                    marginTop: "10px",
                    marginBottom: "10px",
                    flexGrow: 1,
                    "&.MuiFormControlLabel-labelPlacementStart": {
                        justifyContent: "space-between",
                    },
                }}
            />

            {isAlbuminuriaSwitch && (
                <TextField
                    {...register("acr", {
                        valueAsNumber: true,
                        required: lang.scales.urineComponent.albuminuria.acr.errorText,
                        validate: (value) => {
                            if (!Number.isInteger(value) || value < 0) {
                                return lang.scales.urineComponent.albuminuria.acr.errorText;
                            } else {
                                return true;
                            }
                        },
                    })}
                    inputProps={{
                        "data-testid": "form-component-acr", // Aggiungi data-testid qui
                        onWheel: (event: any) => {
                            event.target.blur();
                        },
                    }}
                    label={lang.scales.urineComponent.albuminuria.acr.label}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    error={!!errors.acr}
                    helperText={errors.acr ? String(errors.acr?.message) : ""}
                />
            )}
        </Stack>
    );
}

export default AlbuminuriaData;
